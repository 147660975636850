// Styles
import "./src/style/index.css"
import "./src/style/styles.scss"
import "./src/style/styles.css"
import "./src/style/plugins.css"
import "owl.carousel/dist/assets/owl.carousel.css"
import "owl.carousel/dist/assets/owl.theme.default.css"

const LIBRARIES = [{
    "path": "js/modernizr.js",
    "id": "modernizr"
}, {
    "path": "js/bootstrap.min.js",
    "id": "bootstrap"
}, {
    "path": "js/nav-menu.js",
    "id": "menu"
}, {
    "path": "js/jquery.counterup.min.js",
    "id": "counter"
}, {
    "path": "js/jquery.stellar.min.js",
    "id": "stellar"
}, {
    "path": "js/waypoints.min.js",
    "id": "waypoint"
}, {
    "path": "js/countdown.js",
    "id": "countdown"
}, {
    "path": "js/jquery.magnific-popup.min.js",
    "id": "popup"
}, {
    "path": "js/isotope.pkgd.min.js",
    "id": "isotope"
}, {
    "path": "js/ion.rangeSlider.min.js",
    "id": "rangeSlider"
}, {
    "path": "js/main.js",
    "id": "main"
}]

export const onRouteUpdate = () => {
    injectNecessaryScripts()
}

const injectNecessaryScripts = () => {
    const pathname = window.location.pathname
    const depth = pathname === "/" ? 0 : pathname.split("/").length - 1
    function inject(u, i) {
        var d = document;
        const element = d.getElementById(i)
        if (element) {
            element.remove()
        }
        var s = d.createElement('script');
        s.src = `${"../".repeat(depth)}${u}`;
        s.id = i;
        d.body.appendChild(s);
    }

    LIBRARIES.forEach((library) => {
        inject(library.path, library.id)
    })
}