// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-aboutus-tsx": () => import("./../../../src/pages/aboutus.tsx" /* webpackChunkName: "component---src-pages-aboutus-tsx" */),
  "component---src-pages-comingsoon-tsx": () => import("./../../../src/pages/comingsoon.tsx" /* webpackChunkName: "component---src-pages-comingsoon-tsx" */),
  "component---src-pages-communities-tsx": () => import("./../../../src/pages/communities.tsx" /* webpackChunkName: "component---src-pages-communities-tsx" */),
  "component---src-pages-contactus-tsx": () => import("./../../../src/pages/contactus.tsx" /* webpackChunkName: "component---src-pages-contactus-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-leadership-tsx": () => import("./../../../src/pages/leadership.tsx" /* webpackChunkName: "component---src-pages-leadership-tsx" */),
  "component---src-pages-news-tsx": () => import("./../../../src/pages/news.tsx" /* webpackChunkName: "component---src-pages-news-tsx" */),
  "component---src-templates-property-tsx": () => import("./../../../src/templates/property.tsx" /* webpackChunkName: "component---src-templates-property-tsx" */)
}

